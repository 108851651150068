<template>
  <ContentWrapper>
    <div class="d-flex justify-content-between">
      <div>
        <h6><b>{{ $t(`system.${title.toLowerCase().trim()}`) }}</b></h6>
      </div>
      <div class="additional-icons">
        <i @click="fetchData">
          <font-awesome-icon icon="sync-alt" />
        </i>
        <i
            @click="modalEntityId = 'create'"
            v-if="ifHasPermission([`${overlayModuleName}-${submoduleName}-pin`])"
        >
          <font-awesome-icon icon="plus" />
        </i>
        <!--        <i class="el-icon-circle-plus-outline"/>-->
      </div>
    </div>
    <hr class="mt-2">
    <DataTable
        :headers="headers"
        :idPath="identifierColumn"
        :loading="loading"
        :model="data"
        :pagination="false"
        :params.sync="params"
        @clickRow="onClickRow"
        @updateData="fetchData"
        class="table"
    >
      <template v-slot:item.options="{row}">
        <div class="d-flex mx-2">
          <div
              :key="idx"
              :style="{cursor: item.permission && !ifHasPermission([`${overlayModuleName}-${submoduleName}-${item.permission}`]) ? 'not-allowed' : 'pointer'}"
              @click.stop="!item.permission || ifHasPermission([`${overlayModuleName}-${submoduleName}-${item.permission}`]) ? onClickOptionItem(item.onClick, row) : null"
              class="option"
              v-for="(item, idx) in row.options.items"
          >
            <div :class="{'option-disabled': item.permission && !ifHasPermission([`${overlayModuleName}-${submoduleName}-${item.permission}`])}">
              <el-button
                  :circle="item.attributes && item.attributes.circle ? item.attributes.circle : false"
                  :class="item.attributes && item.attributes.class ? item.attributes.class : ''"
                  :disabled="item.attributes && item.attributes.disabled"
                  :icon="item.attributes && item.attributes.icon ? item.attributes.icon : ''"
                  :plain="item.attributes && item.attributes.plain ? item.attributes.plain : false"
                  :round="item.attributes && item.attributes.round ? item.attributes.round : false"
                  :size="item.attributes && item.attributes.size ? item.attributes.size : 'mini'"
                  :style="item.attributes && item.attributes.style ? item.attributes.style : ''"
                  :type="item.attributes && item.attributes.type ? item.attributes.type : ''"
                  v-if="item.type === 'button'"
              >
                {{ $t(`system.${item.content}`) }}
              </el-button>
              <div
                  :class="item.attributes && item.attributes.class ? item.attributes.class : ''"
                  :style="item.attributes && item.attributes.style ? item.attributes.style : ''"
                  class="option--icon"
                  v-if="item.type === 'icon'"
              >
                <i>
                  <font-awesome-icon :icon="item.content" />
                </i>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataTable>
    <ModalGeneratorWrapper
        :formId="formId"
        :moduleName="overlayModuleName"
        :overlayEntityFormId="overlayEntityFormId"
        :overlayEntityId="overlayEntityId"
        :subModuleName="submoduleName"
        v-model="modalEntityId"
    />
  </ContentWrapper>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert2';
import ContentWrapper from '../../HOCs/ContentWrapper.vue';
import DataTable from '../../Table/DataTable.vue';
import notification from '../../../notification/notify';

export default {
  name: 'Table',
  components: {
    ModalGeneratorWrapper: () => import('../../dialog/ModalGeneratorWrapper.vue'),
    DataTable,
    ContentWrapper,
  },
  props: {
    title: {
      type: String,
    },
    headers: {
      type: Array,
      required: true,
    },
    dataFetchMethod: {
      type: String,
      required: true,
    },
    dataFetchSource: {
      type: String,
      required: true,
    },
    dataFetchParams: {
      type: Object,
      default: () => {
      },
    },
    formId: {
      type: Number,
      required: true,
      // default: 3
    },
    submoduleName: {
      type: String,
    },
    overlayModuleName: {
      type: String,
      required: true,
    },
    overlayEntityId: {
      type: Number,
    },
    overlayEntityFormId: {
      type: Number,
    },
    identifierColumn: {
      type: String,
    },
  },
  data() {
    return {
      data: [],
      params: {
        column: this.headers[0].key,
        direction: 'desc',
        per_page: 5,
        page: 1,
        total: 0,
        per_page_sizes: [5, 30, 50, 100],
      },
      loading: false,
      isModalOpened: false,
      modalEntityId: null,
    };
  },
  beforeMount() {
    this.fetchData();
  },
  watch: {
    modalEntityId(val) {
      if (val === null) {
        this.fetchData(false);
      }
    },
  },
  methods: {
    async fetchData(withLoading = true) {
      if (withLoading) {
        this.loading = true;
      }
      let params = {};
      if (this.dataFetchMethod === 'get') {
        params = {
          params: {
            ...this.params,
            ...this.dataFetchParams,
          },
        };
      } else {
        params = {
          ...this.params,
          ...this.dataFetchParams,
        };
      }
      try {
        const {data} = await axios[this.dataFetchMethod](this.dataFetchSource, params);
        this.data = data.data;
        const optionsHead = this.headers.find(head => head.key === 'options');
        if (optionsHead) {
          optionsHead.sort = false;
          this.data = this.data.map(row => {
            return {...row, options: optionsHead};
          });
        }
        this.params.total = data.total;
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      } finally {
        this.loading = false;
      }
    },
    onClickOptionItem(onClickMethod, row) {
      this[onClickMethod](row);
    },
    onClickRow(id) {
      this.$router.push({
        name: 'ModuleEntity',
        params: {
          moduleName: this.overlayModuleName,
          subModuleName: this.submoduleName,
          formId: this.formId,
          entityId: id,
        },
      });
    },
    unpin(row) {
      swal.fire({
        title: `${this.$t('system.unpin')} ${this.$t(`system.${this.submoduleName}`)} #${this.getId(row)}`,
        text: this.$t('system.are_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      }).then(async (result) => {
        if (result.value) {
          try {
            await axios.delete(`${this.$route.params.moduleName || 'crm'}/${this.submoduleName}/${this.getId(row)}/${this.overlayEntityFormId}/${this.overlayEntityId}/unpin`);
          } catch (e) {
            notification.notify(
                this.$t('notify.error'),
                this.$t('system.can_not_unpin'),
                'error');
          }
          await this.fetchData();
        }
      });
    },
    openInModal(row) {
      this.modalEntityId = this.getId(row);
    },
    getId(row) {
      return row[this.identifierColumn];
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.table {
  margin-bottom: 0 !important;

  .option {
    margin-right: 7px;

    &-disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    &:only-child, &:last-child {
      margin: 0;
    }

    .option--icon {
      padding: 4px 6px;
      border-radius: 5px;
      cursor: pointer;
      /*background: #f2f4f6;*/

      &:hover {
        background: #cbcbcb;
      }
    }
  }
}
</style>
